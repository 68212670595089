@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kode+Mono:wght@400..700&display=swap');
.width40 {
  width: 40%;
}

.width60 {
  width: 60%;
}

.w-100 {
  width: 100%;
}

.width35 {
  width: 35%;
}

.width65 {
  width: 65%;
}

.width33 {
  width: 33%;
}

.w99 {
  width: 99%;
}

.w-35 {
  width: 35%;
}

.purpletxt {
  color: rgb(255, 165, 0);
}

.text-orange {
  color: rgb(255, 165, 0);
}

.purplebg {
  background-color: rgb(255, 165, 0);
}

.width23 {
  width: 23.5%;
}

.boldtypewriter {
  font-family: "typewriter";
  font-weight: 900;
}

.kode-mono-2p-regular {
  font-family: "Press Start 2P", system-ui;
  font-weight: 400;
  font-style: normal;
}

.kode-mono-2p-regular {
  font-family: "Kode Mono", monospace;
  font-weight: 400;
  font-style: normal;
}

.h-1 {
  height: 6px;
  font-family: "Kode Mono", monospace;
  font-size: x-large;
}

.h-01 {
  height: 1px;
  font-family: "Kode Mono", monospace;
  font-size: x-large;
}



.bg-orange {
  background-color: orange; 
  color:black;
}

body {
  margin: 0;
  overflow: auto;
  font-family: "Kode Mono", monospace;
}

h1{
  color: rgb(255, 165, 0);
  margin-bottom: 10px;
  font-size: x-large;
}

h2{
  color: rgb(255, 165, 0);
  margin-bottom: 10px;
  font-size: large;
}

h3{
  color: rgb(255, 165, 0);
  margin-bottom: 10px;
  font-size: large;
}

.cube-container {
  perspective: 1000px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40vmin;
  height: 40vmin;
}

.cube {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transform: rotateY(90deg);
  transition: transform 1s;
}

.face {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #ffa500 0%, #f8af40 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Kode Mono", monospace;
  color: black;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  background: url('./copper.png') no-repeat center center;
  background-size: cover;
}

.face span {
  display: block;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  word-wrap: break-word;
  font-size: calc(0.8vw + 0.8vh + 0.8vmin);
  font-weight: 900;
  text-shadow: 3px 3px 6px rgb(245 245 245 / 61%);
  
}

.float {
  z-index: 999;
}

.text-shadow-black {
  text-shadow: 3px 3px 6px rgb(0, 0, 0);
}



.front  { transform: translateZ(20vmin); }
.back   { transform: rotateY(180deg) translateZ(20vmin); }
.left   { transform: rotateY(-90deg) translateZ(20vmin); }
.right  { transform: rotateY(90deg) translateZ(20vmin); }
.top    { transform: rotateX(90deg) translateZ(20vmin); }
.bottom { transform: rotateX(-90deg) translateZ(20vmin); }

header {
  display: block; /* Ensure the header is displayed */
  z-index: 1000; /* Ensure the header is on top of the cube */
  background-color: black;
  z-index: 999;
}

a {
  color: rgb(255, 165, 0);
}

@media (min-width: 768px) {
  .md\\:flex {
    display: flex !important;
  }
  .md\\:hidden {
    display: none !important;
  }
}


